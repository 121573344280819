<!--
  PACKAGE_NAME : src/pages/euc/device/setting/
  FILE_NAME : index.vue
  AUTHOR : jhsim
  DATE : 2024-04-19
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <template v-for="(item, index) in getComponent">
        <Tab :title="item.title" :key="`Tab${index}`">
          <div v-if="tabIndex === index" class="locker_setting_list sub_new_style01 sub_ui_box1">
            <div class="cusmain-table-wrap">
              <component :is="item.component" :cmList="cmList" />
            </div>
          </div>
        </Tab>
      </template>
    </Tabs>
  </div>
</template>
<script>
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import duplicateList from '@/pages/organize/duplicate/list.vue';

  export default {
    components: {
      Tabs,
      Tab,
      duplicateList,
    },
    props: {},
    watch: {},
    data() {
      return {
        tabIndex: 0,
        cmList: [],
        tabs: {
          selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
        },
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
            formData: {},
          },
        },
        tabItems: [
          {
            title: '중복내선 확인',
            component: duplicateList,
            vender: ['CISCO'],
          },
        ],
      };
    },
    computed: {
      getVender() {
        return this.$_getSystemData('vender')?.configValue || 'CISCO';
      },
      getComponent() {
        return this.tabItems.filter(item => item.vender.includes(this.getVender));
      },
    },
    methods: {
      async createdData() {},
      async tabSelectedIndex(index) {
        // cm data 변경 시 aes, svr에서 반영할 수 있도록 하기 위해 v-if 사용
        this.tabIndex = index;
      },
    },
    created() {
      this.createdData();
    },
    mounted() {},
    destroyed() {},
  };
</script>

<style scoped></style>
